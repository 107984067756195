.casestudy-details-highlights {
  display: flex;
  justify-content: space-between;
  > div {
    border-left: 3px solid #363434;
    padding: 10px 20px;
    // &:first-child{
    //   border-left: 0;
    //   padding-left: 0;
    // }
  }
}

.custom-avatar {
  width: 35px;
  height: 35px;
  align-items: center;
  border-radius: 5px;
}

.awsui_badge_key_services.awsui_badge-color-blue:not(#\ ) {
  background-color: var(--color-background-notification-blue-rjpmdy, #0073bb);
}

.awsui_badge_key_services:not(#\ ) {
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
  hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  color: var(--color-text-body-default-7m2b3r, #16191f);
  font-weight: 400;
  font-family: var(--font-family-base-ua1f64, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: var(--font-body-s-size-ukw2p9, 12px);
  line-height: var(--font-body-s-line-height-kdsbrl, 16px);
  letter-spacing: var(--font-body-s-letter-spacing-p88gax, normal);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  display: inline-block;
  border-radius: var(--border-radius-badge-la3wqa, 16px);
  padding: 0 var(--space-xs-rsr2qu, 8px);
  color: var(--color-text-notification-default-l83xtb, #fafafa);
}

.slide-text-container {
  position: absolute;
  top: 30%;
  left: 20%;
  // width: 100%;
  // height: 100%;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;
}

.headline-text {
  color: white;
  text-align: left;
  white-space: pre-line; /* 让文字可以分行显示 */
  font-size: 40px; /* 调整headline字体大小 */
  font-family: "Arial Rounded MT", sans-serif;
  // display: block;
}

.subheadline-text {
  margin-top: 40px;
  color: white;
  text-align: left;
  white-space: pre-line;
  font-size: 20px; /* 调整subheadline字体大小 */
  font-family: "Arial Rounded MT", sans-serif;
  // display: block;
}

.headline-text-en {
  font-family: "Arial Rounded MT", sans-serif;
}

.headline-text-cn {
  font-family: "KaiTi", sans-serif; /* 可以替换成你喜欢的中文字体 */
}

.subheadline-text-en {
  font-family: "Arial Rounded MT", sans-serif;
}

.subheadline-text-cn {
  font-family: "KaiTi", sans-serif; /* 可以替换成你喜欢的中文字体 */
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Home Page Style
.home-top-bg {
  margin-top: 10px;
  background-image: url("./images/palette.webp");
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
}

.home-box {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 14px rgba(0, 7, 22, 0.14), 0px 0px 4px rgba(65, 77, 92, 0.2);
  border-radius: 16px;
  .home-image {
    cursor: pointer;
    position: relative;
    .video-mask {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }
}

.home-core-feat {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .home-core-feat-item {
    flex-basis: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    .image-item {
      flex: 1;
      background-size: cover;
      background-position: center center;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    .text-item {
      padding: 16px 24px 10px;
    }
  }
}

.home-swiper {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    overflow: hidden;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    // height: 350px;
    object-fit: cover;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.full-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
